import React, { useContext, useState } from "react";

import { FormContext } from "./FormContext";

const Step2 = () => {
  const { step2Input, setStep2Input, setStep2, handleStep2Validation, error2 } =
    useContext(FormContext);

    
  return (
    <div className="my-auto">
      <div className="py-8">
        <div>
          <div className="flex flex-col gap-2 mb-2">
            <label className="block font-semibold text-gray-700">
              Provide responder and incident overview{" "}
              {error2 && step2Input === "" ? (
                <small className="text-red-500">* Required</small>
              ) : (
                ""
              )}
            </label>

            <label className="text-gray-700 text-sm italic">
              Hi there, to get started please say or type the following:
              <ul className="list-disc pl-5">
                <li>
                  Your first and last name and the department you represent.
                </li>
                <li>
                  When and where did the incident occur (include date, time,
                  store number, and address)?
                </li>
              </ul>
            </label>
          </div>

          <textarea
            onChange={(e) => setStep2Input(e.target.value)}
            value={step2Input}
            id="step2Input"
            rows={8}
            className={`rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
            placeholder={``}
          ></textarea>
          <i className="text-xs text-gray-600">
            Input and Edit with Mic & Keyboard
          </i>
        </div>
        <div>
          <button
            onClick={() => setStep2(false)}
            className="mt-8 border px-4 py-2.5 rounded-md bg-transparent text-gray-700 text-sm hover:bg-gray-100"
          >
            Go Back
          </button>
          <button
            onClick={handleStep2Validation}
            className="mt-8 float-right text-white text-sm rounded-md px-4 py-2.5 bg-blue hover:bg-blue"
          >
            Next Step
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step2;
