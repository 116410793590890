import { useState, useRef, useEffect } from "react";
import "./Home.css";
import Record from "../Record/Record";
import { IncidentForm } from "../Form/Form";
import ChatStyle from "../ChatStyle/ChatStyle";

const ToggleSwitch = ({ onToggle }) => {
  // State to control the toggle position (0, 1, or 2)
  const [toggleState, setToggleState] = useState(0);

  // Function to toggle the state
  const handleToggle = (newState) => {
    setToggleState(newState);
    onToggle(newState); // Notify the parent component of the new state
  };

  // Calculate the position of the toggle indicator based on the state
  const togglePosition = () => {
    return `${toggleState * 50}%`; // Divide 100% by 2 positions
  };

  return (
    <div className="mx-auto max-w-md shadow rounded border h-12 mt-4 flex py-1 relative items-center bg-gray-200 toggleComponent text-sm sm:text-base">
      <button
        className="w-full flex justify-center z-10"
        onClick={() => handleToggle(0)}
      >
        Chat Style
      </button>
      {/* <button
        className="w-full flex justify-center z-10"
        onClick={() => handleToggle(1)}
      >
        Form
      </button> */}
      <button
        className="w-full flex justify-center z-10"
        onClick={() => handleToggle(1)}
      >
        Simple Prompt
      </button>

      <span
        className="elSwitch bg-white shadow text-gray-800 flex items-center justify-center w-1/2 rounded h-10 transition-all top-[4px] absolute"
        style={{ left: togglePosition() }}
      ></span>
    </div>
  );
};

export default function Home() {
  // const [isToggled, setIsToggled] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const renderComponent = () => {
    switch (activeIndex) {
      case 0:
        return <ChatStyle />;
      case 2:
        return <IncidentForm />;
      case 1:
        return <Record />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-white">
      {/* <div className="mx-auto max-w-4xl py-8 px-4">
        <div className="mb-8 flex justify-center">
          <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
            Beta-AI Assist AP Report Generator{" "}

          </div>
        </div>
      </div> */}

      <div id="product" className="container mx-auto p-4">
        {/* <p
          className="text-xl mx-auto max-w-sm text-center font-semibold text-gray-700"
          style={{ fontFamily: "Indie Flower" }}
        >
          To generate an incident report, you can choose any option
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="124"
            height="101"
            viewBox="0 0 124 101"
            fill="none"
            class="dHctYWJzb2x1dGU LXR3LXJpZ2h0LTE dHctdG9wLTA dHctaGlkZGVu dHctaC1hdXRv dHctdy0yOA dHctdHJhbnNsYXRlLXgtZnVsbA dHctdGV4dC1zbGF0ZS02MDA sm:dHctYmxvY2s"
          >
            <g clip-path="url(#clip0_46_948)">
              <path
                d="M13.1589 30.5605C25.87 29.4569 33.2606 30.8618 39.6091 42.3857C43.633 49.6899 46.4183 59.3311 46.3238 67.7073C46.2691 72.5626 42.2892 65.9515 41.6704 64.6273C34.5099 49.3076 36.8933 29.794 50.6002 18.9635C59.5333 11.9051 68.6204 16.8471 75.1399 24.5336C85.0823 36.2557 86.6911 53.2905 83.9281 67.8963C83.0089 72.755 81.0734 87.6248 74.4798 88.4661C69.0168 89.1631 70.7054 79.5464 71.3591 76.6577C72.2912 72.5391 74.6187 63.7152 78.7577 61.7025C84.694 58.8161 90.7263 68.0342 92.566 72.2364C96.0595 80.2162 97.0519 90.0519 98.5548 98.5871C98.6653 99.2155 96.1022 96.1168 95.6489 95.5787C94.3499 94.0379 92.4806 93.0934 91.3132 91.4383C90.4471 90.2104 87.3106 82.7634 91.0886 86.4645C92.4201 87.7691 96.7405 98.7863 97.1778 98.7038C98.9644 98.3683 102.67 87.2815 103.339 85.227C104.949 80.2795 94.9776 86.3346 93.782 87.3542"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_46_948">
                <rect
                  width="106"
                  height="67"
                  fill="white"
                  transform="matrix(0.932551 0.361039 0.361039 -0.932551 0 62.481)"
                ></rect>
              </clipPath>
            </defs>
          </svg>
        </p> */}
        {/* <ToggleSwitch onToggle={setIsToggled} /> */}
        <ToggleSwitch onToggle={setActiveIndex} />
      </div>

      {renderComponent()}
    </div>
  );
}
