import React, { useContext, useState, useRef } from "react";
import { FormContext } from "./FormContext";
import thankYou from "../../Assets/images/icon-thank-you.svg";
import Rating from "../Rating/Rating";

const Step6 = () => {
  const {
    setStep5,
    setStep6,
    email,
    setEmail,
    report,
    setReport,
    optionalFeedback,
    setOptionalFeedback,
    handleReSubmit,
    loading,
    loadingEmail,
    error5,
    sendReport,
  } = useContext(FormContext);

  const [emailTags, setEmailTags] = useState(email ? email.split(",") : []); // Initialize with existing emails if any

  const addEmailTag = (event) => {
    if (
      (event.key === "Enter" || event.key === ",") &&
      event.target.value !== ""
    ) {
      event.preventDefault();
      const newEmail = event.target.value.trim();
      if (validateEmail(newEmail)) {
        setEmailTags([...emailTags, newEmail]);
        setEmail(""); // Clear the input field after adding
      } else {
        alert("Please enter a valid email address");
      }
    }
  };

  const removeEmailTag = (indexToRemove) => {
    setEmailTags(emailTags.filter((_, index) => index !== indexToRemove));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailSubmit = () => {
    if (emailTags.length === 0) {
      alert("Please enter at least one email address.");
      return;
    }
    setEmail(emailTags.join(",")); // Join emails as comma-separated string for submission
    sendReport(); // Call the sendReport function
  };

  return (
    <div>
      <div className="text-center pt-2">
        <div className="mx-auto flex justify-center items-center">
          <img src={thankYou} alt="" className="w-16 h-16" />
        </div>
        <div>
          <h2 className="text-xl font-bold my-4">
            AI Assist: Review & Revise - Incident Report
          </h2>
        </div>
      </div>

      <div>
        <div className="mt-4">
          {error5 && report === "" ? (
            <small className="text-red-500">* Required</small>
          ) : (
            ""
          )}

          <textarea
            value={report}
            onChange={(e) => {
              setReport(e.target.value);
            }}
            rows={10}
            placeholder="Your AI powered report will appear here..."
            className={`border rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
          />
        </div>

        <div className="mt-4">
          <label className="block font-semibold text-gray-700 text-start mb-2">
            Report Revision Prompt{" "}
            {error5 && optionalFeedback === "" ? (
              <small className="text-red-500">* Required</small>
            ) : (
              ""
            )}
          </label>
          <textarea
            value={optionalFeedback}
            onChange={(e) => {
              setOptionalFeedback(e.target.value);
            }}
            rows={3}
            placeholder="Great job! Please provide any final thoughts, changes or feedback you’d like to incorporate into the final report."
            className={`border rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
          />
        </div>
      </div>

      <div>
        <button
          onClick={() => {
            setStep6(false);
            setStep5(true);
          }}
          className="mt-8 border px-4 py-2.5 rounded-md bg-transparent text-gray-700 text-sm hover:bg-gray-100"
          disabled={loading}
        >
          Go Back
        </button>
        <button
          className="mt-8 float-right text-white text-sm rounded-md px-4 py-2.5 bg-blue hover:bg-blue"
          onClick={handleReSubmit}
          disabled={loading}
        >
          {loading ? (
            <>
              Generating <i className="fa-solid fa-spinner fa-spin"></i>
            </>
          ) : (
            "Re-Generate"
          )}
        </button>
      </div>

      <div className="relative inline-flex items-center justify-center w-full">
        <hr className="w-full h-0.5 my-8 bg-gray-200 border-0 rounded dark:bg-gray-700" />
        <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 text-gray-700">
          or
        </div>
      </div>

      <h2 className="font-bold text-xl">Submit your Report</h2>

      <Rating />

      <div>
        <label className="font-semibold block mb-2">
          Share your report(Click Return after entering an email address)
          {emailTags.length === 0 && (
            <small className="text-red-500">* Required</small>
          )}
        </label>

        {/* Email Tags Input */}
        <div className="text-sm rounded w-full text-gray-700 focus:outline-none focus:shadow-outline flex-1">
          {emailTags.map((email, index) => (
            <div
              key={index}
              className="inline-block m-1 rounded p-2 bg-gray-100"
            >
              <span>{email}</span>
              <i
                className="fa-solid fa-times cursor-pointer ml-2"
                onClick={() => removeEmailTag(index)}
              ></i>
            </div>
          ))}
          <input
            id="email"
            type="text"
            placeholder="Enter emails and press Enter"
            value={email}
            onKeyDown={addEmailTag}
            onChange={(e) => setEmail(e.target.value)}
            className="outline:none focus:outline-none rounded border border-gray-300"
          />
        </div>

        <button
          className="mt-8 float-right text-white text-sm rounded-md px-4 py-2.5 bg-blue hover:bg-blue"
          onClick={handleEmailSubmit}
          disabled={loadingEmail}
        >
          {loadingEmail ? (
            <>
              Sending <i className="fa-solid fa-spinner fa-spin"></i>
            </>
          ) : (
            "Send Report"
          )}
        </button>
      </div>
    </div>
  );
};

export default Step6;
