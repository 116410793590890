import React, { useContext, useState } from "react";

import { FormContext } from "./FormContext";

const Step4 = () => {
  const {
    step4Input,
    setStep4Input,
    setStep3,
    setStep4,
    handleStep4Validation,
    error4,
    selectedIncident,
  } = useContext(FormContext);

  let placeholder;
  console.log(selectedIncident);
  switch (selectedIncident) {
    case "Recovery":
      placeholder = `Almost there!
. Briefly describe what took place.
. Describe the merchandise that was recovered, its value, and details of any contact the suspect had with loss prevention or law enforcement, including whether the suspect was trespassed.
. Remember to provide a voided receipt.
      `;
      break;
    case "Grab and Run theft":
      placeholder = `Almost there!
. Briefly describe what took place.
. Describe the merchandise that was stolen, its value, and details of any contact the suspect had with loss prevention or law enforcement, including whether the suspect was trespassed.
. Remember to provide a voided receipt.
      `;
      break;
    case "Disorderly Conduct":
      placeholder = `Almost there!
 . Briefly summarize what took place, describe the suspect’s actions and if the subject was trespassed. If the police were called, was the suspect arrested? If applicable, provide the police case number.
     `;
      break;
    case "Parking Lot Incidents":
      placeholder = `Almost there!
. Briefly summarize what took place but be as detailed as possible.
. If applicable, provide the color, make, model and license plate(s) of the vehicle(s) involved.
. If the police were called, was the suspect arrested? If so, provide the police case number.

     `;
      break;
    default:
      placeholder = `Almost there!
. Briefly describe what took place.
. Describe the merchandise that was recovered, its value, and details of any contact the suspect had with loss prevention or law enforcement, including whether the suspect was trespassed.
. Remember to provide a voided receipt.`;
      break;
  }

  return (
    <div className="my-auto">
      <div className="py-8">
        <div>
          <div className="flex flex-col gap-2 mb-2">
            <label className="block font-semibold text-gray-700">
              Provide incident specifics{" "}
              {error4 && step4Input === "" ? (
                <small className="text-red-500">* Required</small>
              ) : (
                ""
              )}
            </label>
            <label className="text-gray-700 text-sm italic">
              <pre>{placeholder}</pre>
            </label>
          </div>

          <textarea
            onChange={(e) => setStep4Input(e.target.value)}
            value={step4Input}
            id="step3Input"
            rows={8}
            className={`rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
            placeholder={``}
          ></textarea>
          <i className="text-xs text-gray-600">
            Input and Edit with Mic & Keyboard
          </i>
        </div>
        <div>
          <button
            onClick={() => {
              setStep4(false);
              setStep3(true);
            }}
            className="mt-8 border px-4 py-2.5 rounded-md bg-transparent text-gray-700 text-sm hover:bg-gray-100"
          >
            Go Back
          </button>
          <button
            onClick={handleStep4Validation}
            className="mt-8 float-right text-white text-sm rounded-md px-4 py-2.5 bg-blue hover:bg-blue"
          >
            Next Step
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step4;
