import React, { useContext, useState } from "react";
import Step1 from "./Step1";
import { FormContext } from "./FormContext";
import classNames from "classnames";

import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";

const Form = () => {
  const {
    step2,
    step3,
    step4,
    step5,
    step6,
    setStep1,
    setStep2,
    setStep3,
    setStep4,
    setStep5,
    setStep6,
    allStepsFalse,
  } = useContext(FormContext);

  return (

    <div className="flex justify-center md:pt-10 relative">
      <div className="flex flex-col md:flex-row gap-12 bg-white md:p-4 rounded-xl h-auto w-full max-w-4xl ">
        <div className="w-screen bg-blue absolute md:relative top-0 left-0 md:w-1/3 md:h-full bg-no-repeat bg-cover pb-12 z-0">
        <div className="absolute bottom-0 left-0 w-0 h-0 border-l-[50px] border-l-yellow border-t-[50px] border-t-transparent"></div>
          {/* <img src={sideBarLg} alt="" /> */}
          <div className="flex md:flex-col gap-6 sm:gap-8 justify-center md:pl-4 lg:pl-12  p-6 md:pt-14">
            <div className="flex items-center gap-2">
              <div
                className={classNames(
                  "cursor-pointer h-8 w-8 min-w-8 rounded-[50%] bg-lime-100 border flex justify-center items-center text-slate-950 text-sm",
                  {
                    "text-zinc-50 bg-transparent font-bold":
                      step2 || step3 || step4 || step5 || step6,
                  }
                )}
                onClick={() => {
                  allStepsFalse();
                  setStep1(true);
                }}
              >
                1
              </div>
              <div>
                <p className="hidden md:block  text-sm text-slate-400">
                  STEP 1
                </p>
                <p className="hidden md:block text-xs text-slate-300 italic">
                  Choose your incident type
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div
                className={classNames(
                  "cursor-pointer h-8 w-8 min-w-8 rounded-[50%]  border flex justify-center items-center text-slate-50 text-sm",
                  { "bg-lime-100 text-slate-950 font-bold": step2 }
                )}
                onClick={() => {
                  allStepsFalse();
                  setStep2(true);
                }}
              >
                2
              </div>
              <div>
                <p className="hidden md:block text-sm text-slate-400">STEP 2</p>
                <p className="hidden md:block text-xs text-slate-300 italic">
                  Provide responder and incident overview
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div
                className={classNames(
                  "cursor-pointer h-8 w-8 min-w-8 rounded-[50%] border flex justify-center items-center text-slate-50 text-sm",
                  { "bg-lime-100 text-slate-950 font-bold": step3 }
                )}
                onClick={() => {
                  allStepsFalse();
                  setStep3(true);
                }}
              >
                3
              </div>
              <div>
                <p className="hidden md:block  text-sm text-slate-400">
                  STEP 3
                </p>
                <p className="hidden md:block text-xs text-slate-300 italic">
                  Provide suspect details
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div
                className={classNames(
                  "cursor-pointer h-8 w-8 min-w-8 rounded-[50%] border flex justify-center items-center text-slate-50 text-sm",
                  { "bg-lime-100 text-slate-950 font-bold": step4 }
                )}
                onClick={() => {
                  allStepsFalse();
                  setStep4(true);
                }}
              >
                4
              </div>
              <div>
                <p className="hidden md:block  text-sm text-slate-400">
                  STEP 4
                </p>
                <p className="hidden md:block text-xs text-slate-300 italic">
                  Provide incident specifics
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div
                className={classNames(
                  "cursor-pointer h-8 w-8 min-w-8 rounded-[50%] border flex justify-center items-center text-slate-50 text-sm",
                  { "bg-lime-100 text-slate-950 font-bold": step5 }
                )}
                onClick={() => {
                  allStepsFalse();
                  setStep5(true);
                }}
              >
                5
              </div>
              <div>
                <p className="hidden md:block text-sm text-slate-400">STEP 5</p>
                <p className="hidden md:block text-xs text-slate-300 italic">
                  Upload incident image
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div
                className={classNames(
                  "cursor-pointer h-8 w-8 min-w-8 rounded-[50%] border flex justify-center items-center text-slate-50 text-sm",
                  { "bg-lime-100 text-slate-950 font-bold": step6 }
                )}
                onClick={() => {
                  allStepsFalse();
                  setStep6(true);
                }}
              >
                6
              </div>
              <div>
                <p className="hidden md:block  text-sm text-slate-400">
                  REPORT
                </p>
              </div>
            </div>
          </div>
        </div>


        <div className="text-black rounded-lg bg-white px-3 pt-2 md:pt-6 w-full md:w-2/3 pr-4 mt-20 md:mt-2 z-20">
          {step2 ? (
            <Step2 />
          ) : step3 ? (
            <Step3 />
          ) : step4 ? (
            <Step4 />
          ) : step5 ? (
            <Step5 />
          ) : step6 ? (
            <Step6 />
          ) : (
            <Step1 />
          )}
        </div>

        {/* <Step1 /> */}
      </div>
    </div>
  );
};

export default Form;
