import React, { useEffect, useState, useRef } from "react";
import "./Record.css";
import leftCurlArrow from "../../Assets/left-curl-arrow.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InstructionsModal = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const modalRef = useRef(null);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  // Effect to handle the body scroll lock
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden"; // Disable scroll when modal is open
    } else {
      document.body.style.overflow = "visible"; // Enable scroll when modal is closed
    }

    return () => {
      document.body.style.overflow = "visible"; // Ensure scroll is enabled after component unmounts
    };
  }, [isModalOpen]);

  // Close modal on clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  useEffect(() => {
    // This will run when `isModalOpen` changes.
    if (isModalOpen) {
      // Query all elements with the class 'toggleComponent'
      const elements = document.getElementsByClassName("toggleComponent");

      // Iterate over the NodeList and set the z-index to -1
      Array.from(elements).forEach((element) => {
        // Store the original z-index, then set it to -1
        element.dataset.originalZIndex = element.style.zIndex;
        element.style.zIndex = -1;
      });
    } else {
      // When the modal closes, reset the z-index
      const elements = document.getElementsByClassName("toggleComponent");
      Array.from(elements).forEach((element) => {
        // Reset the z-index to its original value
        element.style.zIndex = element.dataset.originalZIndex || "";
      });
    }
  }, [isModalOpen]); // Dependency array ensures this effect runs when `isModalOpen` changes.

  return (
    <div>
      <button
        onClick={toggleModal}
        className="my-6 rounded-sm px-3 py-2 text-sm leading-6 text-white bg-yellow"
      >
        User Guide
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-100 px-2">
          <div
            ref={modalRef}
            className="relative top-20 mx-auto p-4 border w-full max-w-2xl shadow-lg rounded-md bg-white"
          >
            <div className="text-start py-8">
              <h2 className="text-lg text-center font-bold text-gray-600 py-2">
                INSTRUCTIONS: PLEASE READ FOR HELP CONSTRUCTING YOUR REPORT
              </h2>
              <div
                className="absolute top-2 right-2 w-6 h-6 bg-gray-200 rounded-full flex items-center justify-center cursor-pointer"
                onClick={toggleModal}
              >
                <i class="fas fa-times text-xs"></i>
              </div>

              {/* <button
                onClick={toggleModal}
                className="absolute top-0 right-0 p-2"
              >
                Close
              </button> */}
              <div>
                <p className="py-2">
                  Your report will need the following basic information:
                </p>
                <ul className="pl-6 text-gray-600 py-2">
                  <li className="list-disc">
                    The date, time, location, and, if applicable, store number
                    of the incident.
                  </li>
                  <li className="list-disc">
                    Type of incident-theft, recovery, trespass, other (specify).
                  </li>
                  <li className="list-disc">
                    Was something stolen or recovered?
                  </li>
                  <li className="list-disc">
                    If so,the value of what was stolen/recovered.
                  </li>
                  <li className="list-disc">
                    If known, the name, race, gender, physical traits, age, and
                    approximate age of the suspect (if known). If unknown, say
                    so.
                  </li>
                  <li className="list-disc">
                    All of the above needs to be detailed in a summary.
                  </li>
                </ul>
              </div>
              <div>
                <p className="py-2">
                  If applicable, for more in depth incidents, your report may
                  need the following:
                </p>
                <ul className="pl-6 text-gray-600 py-2">
                  <li className="list-disc">If you recognized the suspect.</li>
                  <li className="list-disc">
                    Was the suspect a repeat offender?
                  </li>
                  <li className="list-disc">Was the suspect violent?</li>
                  <li className="list-disc">
                    Whether selection, concealment, and passing all points of
                    sale without payment were observed.
                  </li>
                  <li className="list-disc">
                    If known, how did the suspect flee? Foot, vehicle, other
                    (specify).
                  </li>
                  <li className="list-disc">
                    In a vehicle? Specify make, model, color, license plate,
                    etc.
                  </li>
                  <li className="list-disc">
                    Whether the incident was caught on video.
                  </li>
                  <li className="list-disc">
                    Was the suspect kept under constant observation? If not, say
                    so.
                  </li>
                  <li className="list-disc">
                    If the police responded and you dealt with them, provide the
                    name of the officer and the police case number.
                  </li>
                  <li className="list-disc">
                    If any of these things apply, include them in your detailed
                    summary in addition to the basic information above.
                  </li>
                </ul>
              </div>
              <p className="py-2">
                <span className="text-red-500 font-semibold">Disclaimer:</span>{" "}
                The listed items above are by no means all-inclusive. They're
                provided only for guidance. Each incident is unique and it's on
                you to include all the necessary details.
              </p>

              {/* <p className="py-2 font-semibold">
                When you think you're ready to dictate your report simply tap
                “Try it Out” below. Then tap inside the prompt box and your
                keyboard will appear. Then tap the microphone icon on your
                keyboard and begin speaking. You can also type it out, but
                speaking saves time.
              </p>

              <p className="py-2 font-semibold">
                If you mess it up, don’t worry. The system will typically
                understand what you meant. If not, you can always edit it or try
                again.
              </p> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function RatingButton({ isSelected, onClick }) {
  const commonRatingButtonClasses =
    "w-8 h-8 flex justify-center items-center rounded-full button-sizing hover:text-yellow-400 active:text-yellow-400 cursor-pointer";
  const ratingButtonUnselectedClasses = `${commonRatingButtonClasses} text-gray-400`;
  const ratingButtonSelectedClasses = `${commonRatingButtonClasses} text-yellow-400`;

  return (
    <span
      className={
        isSelected ? ratingButtonSelectedClasses : ratingButtonUnselectedClasses
      }
      onClick={onClick}
    >
      <i
        class={`text-xl ${
          isSelected ? "fa-solid fa-star" : "fa-regular fa-star"
        }`}
      ></i>
    </span>
  );
}

function Record() {
  const [rating, setRating] = useState("");

  const [imageDescription, setImageDescription] = useState("");

  const [isValidEmail, setIsValidEmail] = useState(true);
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Regex pattern for validating email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailPattern.test(value));
  };

  const [transcription, setTranscription] = useState("");
  const [report, setReport] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({
    imageDescription: false,
    report: false,
    transcription: false,
  });

  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState("");
  const fileInputRef = useRef(null);

  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const handleFile = (e) => {
    if (files.length >= 1) {
      setMessage("Cannot upload more than 1 image");
      return;
    }

    setMessage("");
    let newFile = e.target.files[0];

    // Check file size (size is in bytes, so 15 MB is 15 * 1024 * 1024 bytes)
    const maxSize = 15 * 1024 * 1024; // 15 MB
    if (newFile.size > maxSize) {
      setMessage("File size exceeds 15 MB. Please upload a smaller file.");
      return;
    }

    const fileType = newFile["type"];
    const validImageTypes = [
      "image/png",
      "image/jpeg",
      "image/webp",
      "image/gif",
    ];
    if (!validImageTypes.includes(fileType)) {
      setMessage(
        "Invalid file type. Only PNG, JPG, JPEG, WEBP, and GIF are allowed."
      );
      return;
    }

    setFiles([newFile]);
    fileInputRef.current.value = null; // Clear the file input after upload
  };

  const removeImage = () => {
    setFiles([]);
    fileInputRef.current.value = null; // Clear the file input after deletion
  };

  const uploadAndAnalyzeImage = async () => {
    const formData = new FormData();
    formData.append("file", files[0]);

    formData.append("userInput", imageDescription);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/analyzeImage`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload and analyze image");
      }

      const result = await response.json();
      console.log(result);
      return result.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getReport = async () => {
    let formIsValid = true;
    const requiredFields = {
      transcription,
    };
    const newErrors = { ...errors };

    Object.keys(requiredFields).forEach((field) => {
      if (!requiredFields[field]) {
        formIsValid = false;
        newErrors[field] = true;
      } else {
        newErrors[field] = false;
      }
    });

    setErrors(newErrors);

    if (files.length > 0 && !imageDescription) {
      formIsValid = false;
      newErrors["imageDescription"] = true;
    }

    if (!formIsValid) {
      return;
    }

    setLoading1(true);

    try {
      let prompt = `Incident Details: ${transcription}`;

      if (files.length > 0) {
        const analyzedImage = await uploadAndAnalyzeImage();
        prompt = `Incident Details: ${transcription}
        Incident image analyzed data: ${analyzedImage}`;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/chat`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userMessage: prompt,
            type: "simplePrompt",
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const finalResponse = await response.json();
      console.log(finalResponse);
      if (finalResponse) {
        setLoading1(false);
        setReport(finalResponse.data);
      }
    } catch (e) {
      setLoading1(false);
      toast.error("Error: Failed to send report");
      return e;
    }
  };

  const sendReport = async () => {
    let formIsValid = true;
    const requiredFields = {
      report,
      rating,
      email,
      isValidEmail,
    };
    const newErrors = { ...errors };

    Object.keys(requiredFields).forEach((field) => {
      if (!requiredFields[field]) {
        formIsValid = false;
        newErrors[field] = true;
      } else {
        newErrors[field] = false;
      }
    });

    setErrors(newErrors);

    if (!formIsValid) {
      return;
    }

    const formData = new FormData();
    formData.append("userMessage", prompt);
    formData.append("type", "simplePrompt");
    formData.append("rating", rating);
    formData.append("email", email);

    if (files.length > 0) {
      files.forEach((file) => formData.append("file", file));
    }

    // Append report if available
    if (report) {
      formData.append("report", report);
    }

    setLoading2(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/email`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result?.success) {
        setLoading2(false);
        toast.success("Report sent to email successfully!");
      }
    } catch (e) {
      setLoading2(false);
      console.error("Fetch error:", e.message);
      toast.error("Error: Failed to send report");
      return e;
    }
  };

  return (
    <div className="max-w-2xl mx-auto py-8 px-4 text-center">
      <ToastContainer />


      <div className="mt-8">
        <label className="block font-semibold text-gray-700 text-start mb-2">
          Effortlessly narrate your entire incident here with voice-to-text, or
          enhance a pre-written report with expert finishing touches.{" "}
          {errors.transcription && (
            <small className="text-red-500">* Required</small>
          )}
        </label>

        <InstructionsModal />

        <textarea
          value={transcription}
          onChange={(e) => {
            setTranscription(e.target.value);
            setErrors({ ...errors, transcription: false });
          }}
          rows="15"
          cols="50"
          placeholder=""
          className={`border ${
            errors.transcription ? "border-red-500" : "border-gray-300"
          } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
        />

        <div className="my-4">
          <label className="text-gray-600 font-semibold block mb-2">
            Upload image related to the incident happened (Optional)
          </label>
          <div className="rounded-md border border-gray-600 bg-gray-50 w-full">
            <div className="m-4">
              <span className="flex justify-center items-center text-[12px] mb-1 text-red-500">
                {message}
              </span>
              <div className="flex items-center justify-center w-full">
                <label className="flex cursor-pointer flex-col w-full h-32 border-2 rounded-md border-dashed hover:bg-gray-100 hover:border-gray-300">
                  <div className="flex flex-col items-center justify-center pt-7">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      Select a photo
                    </p>
                  </div>
                  <input
                    ref={fileInputRef}
                    type="file"
                    onChange={handleFile}
                    className="opacity-0"
                    name="file"
                  />
                </label>
              </div>
              <div className="flex flex-wrap gap-2 mt-2">
                {files.length > 0 && (
                  <div className="relative overflow-hidden group">
                    <img
                      className="h-20 w-20 rounded-md object-cover"
                      src={URL.createObjectURL(files[0])}
                      alt="Preview"
                    />
                    <i
                      className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 fas fa-trash cursor-pointer text-white"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                      onClick={removeImage}
                    ></i>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {files.length > 0 && (
          <div className="mb-6">
            <label className="block font-semibold text-gray-700 mb-2">
              Description{" "}
              {errors.imageDescription ? (
                <small className="text-red-500">* Required</small>
              ) : (
                ""
              )}
            </label>
            <textarea
              onChange={(e) => {
                setImageDescription(e.target.value);
                setErrors({ ...errors, imageDescription: false });
              }}
              value={imageDescription}
              id="imageDescription"
              rows={3}
              className={`border ${
                errors.imageDescription ? "border-red-500" : "border-gray-300"
              } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
              placeholder="Please describe what the image is and why you are including it"
            ></textarea>
          </div>
        )}
      </div>

      {/* <div className="px-4 my-4 flex flex-col gap-2 items-center justify-center">
        <img src={leftCurlArrow} width={50} height={50}></img>
        <p
          className="text-lg mx-auto max-w-md text-center font-semibold text-gray-700"
          style={{ fontFamily: "Indie Flower" }}
        >
          You can change your report anytime here or below. Remember to
          proofread and verify the report is accurate. AP Ally isn’t responsible
          for wrong information.
        </p>
      </div> */}

      <button
        className="mt-8 text-white rounded-md px-4 py-2.5 bg-blue hover:bg-blue"
        onClick={getReport}
        disabled={loading1}
      >
        {loading1 ? (
          <>
            Generating <i className="fa-solid fa-spinner fa-spin"></i>
          </>
        ) : (
          "Get Incident Report"
        )}
      </button>

      {report && (
        <div>
          <div className="my-8">
            <label className="block font-semibold text-gray-700 text-start mb-2">
              Incident Report{" "}
              {errors.report && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <textarea
              value={report}
              onChange={(e) => {
                setReport(e.target.value);
                setErrors({ ...errors, report: false });
              }}
              rows="10"
              cols="50"
              placeholder="Your AI powered report will appear here..."
              className={`border ${
                errors.report ? "border-red-500" : "border-gray-300"
              } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
            />
          </div>

          <div class="relative inline-flex items-center justify-center w-full">
            <hr class="w-full h-0.5 my-8 bg-gray-200 border-0 rounded dark:bg-gray-700" />
            <div class="absolute px-4 bg-gray-50 -translate-x-1/2 left-1/2 text-gray-700">
              or
            </div>
          </div>

          <h2 className="font-bold text-xl">Submit your Report</h2>

          <div className="container mx-auto py-8">
            <h1 className="block font-semibold">
              How did we do?{" "}
              {!rating ? (
                <small className="text-xs font-semibold text-red-500">
                  * Required
                </small>
              ) : (
                ""
              )}
            </h1>
            <p className="mt-2 text-lightGrey leading-5 text-sm">
              Please let us know how we did with your support request. All
              feedback is appreciated to help us improve our offering!
            </p>
            <div className="flex flex-row items-center space-x-2 mt-4">
              {[1, 2, 3, 4, 5].map((number) => (
                <RatingButton
                  key={number}
                  isSelected={rating >= number}
                  onClick={() => setRating(number)}
                />
              ))}
            </div>
          </div>

          <div>
            <label className="font-semibold block mb-2">
              Share your report(Click Return after entering an email address)
              {!email && <small className="text-red-500">* Required</small>}
              {!isValidEmail && (
                <small className="text-red-500">
                  * Please type correct email address
                </small>
              )}
            </label>

            <input
              id="email"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              className={`border ${
                !email ? "border-red-500" : "border-gray-300"
              } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
            />
          </div>

          <div className="mt-8 flex justify-between">
            <button
              className="mt-8 text-white rounded-md px-4 py-2.5 text-sm bg-blue hover:bg-blue"
              onClick={sendReport}
              disabled={loading2}
            >
              {loading2 ? (
                <>
                  Sending <i className="fa-solid fa-spinner fa-spin"></i>
                </>
              ) : (
                "Send Report"
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Record;
