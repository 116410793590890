import { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalComponent from "../Modal/Modal";

function RatingButton({ isSelected, onClick }) {
  const commonRatingButtonClasses =
    "w-8 h-8 flex justify-center items-center rounded-full button-sizing hover:text-yellow-400 active:text-yellow-400 cursor-pointer";
  const ratingButtonUnselectedClasses = `${commonRatingButtonClasses} text-gray-400`;
  const ratingButtonSelectedClasses = `${commonRatingButtonClasses} text-yellow-400`;

  return (
    <span
      className={
        isSelected ? ratingButtonSelectedClasses : ratingButtonUnselectedClasses
      }
      onClick={onClick}
    >
      <i
        class={`text-xl ${
          isSelected ? "fa-solid fa-star" : "fa-regular fa-star"
        }`}
      ></i>
    </span>
  );
}

export const IncidentForm = () => {
  const [rating, setRating] = useState("");

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [storeNumber, setStoreNumber] = useState("");
  const [suspectName, setSuspectName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [race, setRace] = useState("");
  const [other, setOther] = useState("");
  const [other1, setOther1] = useState("");
  const [heightWeightBuild, setHeightWeightBuild] = useState("");
  const [hairColor, setHairColor] = useState("");
  const [clothing, setClothing] = useState("");
  const [aiFullName, setAiFullName] = useState("");
  const [aiDepartment, setAiDepartment] = useState("");
  const [repeatOffender, setRepeatOffender] = useState("");
  const [incidentType, setIncidentType] = useState("");
  const [lostAmount, setLostAmount] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [officerName, setOfficerName] = useState("");
  const [caseNumber, setCaseNumber] = useState("");
  const [incidentSummary, setIncidentSummary] = useState("");
  const [subjectTrespassed, setSubjectTrespassed] = useState("");
  const [violentOffender, setViolentOffender] = useState("");
  const [surveillance, setSurveillance] = useState("");
  const [stolenItems, setStolenItems] = useState("");
  const [policeResponded, setPoliceResponded] = useState("");
  const [imageDescription, setImageDescription] = useState("");
  const [errors, setErrors] = useState({
    date: false,
    time: false,
    storeNumber: false,
    suspectName: false,
    age: false,
    gender: false,
    race: false,
    other: false,
    heightWeightBuild: false,
    hairColor: false,
    clothing: false,
    aiFullName: false,
    aiDepartment: false,
    repeatOffender: false,
    incidentType: false,
    other1: false,
    lostAmount: false,
    departmentName: false,
    officerName: false,
    caseNumber: false,
    incidentSummary: false,
    subjectTrespassed: false,
    violentOffender: false,
    surveillance: false,
    stolenItems: false,
    policeResponded: false,
    imageDescription: false,
  });

  const [report, setReport] = useState("");
  const [reportCounter, setReportCounter] = useState(false);

  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState("");
  const fileInputRef = useRef(null);

  const [prompt, setPrompt] = useState("");

  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Regex pattern for validating email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailPattern.test(value));
  };

  const handleFile = (e) => {
    if (files.length >= 1) {
      setMessage("Cannot upload more than 1 image");
      return;
    }

    setMessage("");
    let newFile = e.target.files[0];

    // Check file size (size is in bytes, so 15 MB is 15 * 1024 * 1024 bytes)
    const maxSize = 15 * 1024 * 1024; // 15 MB
    if (newFile.size > maxSize) {
      setMessage("File size exceeds 15 MB. Please upload a smaller file.");
      return;
    }

    const fileType = newFile["type"];
    const validImageTypes = [
      "image/png",
      "image/jpeg",
      "image/webp",
      "image/gif",
    ];
    if (!validImageTypes.includes(fileType)) {
      setMessage(
        "Invalid file type. Only PNG, JPG, JPEG, WEBP, and GIF are allowed."
      );
      return;
    }

    setFiles([newFile]);
    fileInputRef.current.value = null; // Clear the file input after upload
  };

  const removeImage = () => {
    setFiles([]);
    fileInputRef.current.value = null; // Clear the file input after deletion
  };

  const uploadAndAnalyzeImage = async () => {
    const formData = new FormData();
    formData.append("file", files[0]);

    formData.append("userInput", imageDescription);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/analyzeImage`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload and analyze image");
      }

      const result = await response.json();
      console.log(result);
      return result.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    let formIsValid = true;
    const requiredFields = {
      date,
      time,
      storeNumber,
      suspectName,
      age,
      gender,
      race,
      // other,
      heightWeightBuild,
      hairColor,
      clothing,
      aiFullName,
      aiDepartment,
      repeatOffender,
      incidentType,
      // other1,
      lostAmount,
      // departmentName,
      // officerName,
      // caseNumber,
      incidentSummary,
      subjectTrespassed,
      violentOffender,
      surveillance,
      stolenItems,
      policeResponded,
    };
    const newErrors = { ...errors };

    Object.keys(requiredFields).forEach((field) => {
      if (!requiredFields[field]) {
        formIsValid = false;
        newErrors[field] = true;
      } else {
        newErrors[field] = false;
      }
    });

    setErrors(newErrors);

    if (race === "Other" && !other.trim()) {
      formIsValid = false;
      newErrors["other"] = true; // Adding "other" field error
    } else if (race !== "Other") {
      newErrors["other"] = false; // Ensure no error for "other" if "Other" is not selected
    }

    if (incidentType === "Other" && !other1.trim()) {
      formIsValid = false;
      newErrors["other1"] = true; // Adding "other1" field error
    } else if (incidentType !== "Other") {
      newErrors["other1"] = false; // Ensure no error for "other1" if "Other" is not selected
    }

    if (policeResponded === "No") {
      newErrors["departmentName"] = false;
      newErrors["officerName"] = false;
      newErrors["caseNumber"] = false;
    }
    if (policeResponded === "Yes") {
      formIsValid = false;
      newErrors["departmentName"] = true;
      newErrors["officerName"] = true;
      newErrors["caseNumber"] = true;
    }

    if (files.length > 0 && !imageDescription) {
      formIsValid = false;
      newErrors["imageDescription"] = true;
    }

    if (formIsValid) {
      setLoading(true);
      let newPrompt = "";
      if (files.length > 0) {
        const analyzedImage = await uploadAndAnalyzeImage();
        newPrompt = `
Please generate a detailed incident report with the following information:
Officer/Employee Name: ${aiFullName}
Officer/Employee Department: ${aiDepartment}
Date/Time: ${date} at ${time}
Store Number or Address: ${storeNumber}
Suspect Name: ${suspectName}
Suspect kept under constant surveillance? ${surveillance}
Repeat Offender: ${repeatOffender}
Subject Trespassed: ${subjectTrespassed}
Violent Offender: ${violentOffender}
Age: ${age}
Gender: ${gender}
Race: ${race === "Other" ? other : race}
Height, Weight, Build: ${heightWeightBuild}
Hair/Eye Color: ${hairColor}
Clothing Description: ${clothing}
Incident Type: ${incidentType === "Other" ? other1 : incidentType}
Describe what was stolen or recovered: ${stolenItems}
Total of value of merchandise stolen/recovered: ${lostAmount}
Police Responded: ${policeResponded}
Police Agency Officer Name: ${officerName}
Police Agency Department Name: ${departmentName}
Police Agency Case Number: ${caseNumber}
Incident Summary: ${incidentSummary}
${
  analyzedImage.data
    ? `Incident image analyzed data: ${analyzedImage.data}`
    : ""
}`;
      } else {
        newPrompt = `
Please generate a detailed incident report with the following information:
Officer/Employee Name: ${aiFullName}
Officer/Employee Department: ${aiDepartment}
Date/Time: ${date} at ${time}
Store Number or Address: ${storeNumber}
Suspect Name: ${suspectName}
Suspect kept under constant surveillance? ${surveillance}
Repeat Offender: ${repeatOffender}
Subject Trespassed: ${subjectTrespassed}
Violent Offender: ${violentOffender}
Age: ${age}
Gender: ${gender}
Race: ${race === "Other" ? other : race}
Height, Weight, Build: ${heightWeightBuild}
Hair/Eye Color: ${hairColor}
Clothing Description: ${clothing}
Incident Type: ${incidentType === "Other" ? other1 : incidentType}
Describe what was stolen or recovered: ${stolenItems}
Total of value of merchandise stolen/recovered: ${lostAmount}
Police Responded: ${policeResponded}
Police Agency Officer Name: ${officerName}
Police Agency Department Name: ${departmentName}
Police Agency Case Number: ${caseNumber}
Incident Summary: ${incidentSummary}`;
      }
      setPrompt(newPrompt);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/chat`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userMessage: newPrompt,
              type: "form",
            }),
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const finalResponse = await response.json();
        console.log(finalResponse);
        if (finalResponse) {
          setLoading(false);
          setReport(finalResponse.data);
          setReportCounter(true);
        }
      } catch (e) {
        setLoading(false);
        toast.error("Error: Failed to send report");
        return e;
      }
    }
  };

  const sendReport = async () => {
    let formIsValid = true;
    const requiredFields = {
      report,
      rating,
      email,
      isValidEmail,
    };
    const newErrors = { ...errors };

    Object.keys(requiredFields).forEach((field) => {
      if (!requiredFields[field]) {
        formIsValid = false;
        newErrors[field] = true;
      } else {
        newErrors[field] = false;
      }
    });

    setErrors(newErrors);

    if (!formIsValid) {
      return;
    }

    const formData = new FormData();
    formData.append("userMessage", prompt);
    formData.append("type", "form");
    formData.append("rating", rating);
    formData.append("email", email);

    if (files.length > 0) {
      files.forEach((file) => formData.append("file", file));
    }

    // Append report if available
    if (report) {
      formData.append("report", report);
    }

    setLoading1(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/email`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result?.success) {
        setLoading1(false);
        setIsModalOpen(true);
      }
    } catch (e) {
      setLoading1(false);
      console.error("Fetch error:", e.message);
      toast.error("Error: Failed to send report");
      return e;
    }
  };

  const resetForm = () => {
    setRating("");
    setReport("");
    setEmail("");
    setDate("");
    setTime("");
    setStoreNumber("");
    setSuspectName("");
    setAge("");
    setGender("");
    setRace("");
    setOther("");
    setOther1("");
    setHeightWeightBuild("");
    setHairColor("");
    setClothing("");
    setAiFullName("");
    setAiDepartment("");
    setRepeatOffender("");
    setIncidentType("");
    setLostAmount("");
    setDepartmentName("");
    setOfficerName("");
    setCaseNumber("");
    setIncidentSummary("");
    setSubjectTrespassed("");
    setViolentOffender("");
    setSurveillance("");
    setStolenItems("");
    setPoliceResponded("");
    setFiles([]);
    setImageDescription("");
    // Reset additional states as needed
  };

  return (
    <div className="max-w-2xl mx-auto py-8 px-4">
      <ToastContainer />
      <div className="bg-white p-4 sm:p-8 rounded-md border border-gray-400 max-w-2xl mx-auto my-8">
        <h1 className="text-xl sm:text-2xl font-semibold text-gray-700 text-center mb-6">
          Incident report form
        </h1>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Associate Information{" "}
            {(errors.aiFullName || errors.aiDepartment) && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <div className="flex flex-col sm:flex-row gap-3">
            <input
              id="aiFullName"
              type="text"
              placeholder="Full Name"
              value={aiFullName}
              onChange={(e) => {
                setAiFullName(e.target.value);
                setErrors({ ...errors, aiFullName: false });
              }}
              className={`border ${
                errors.aiFullName ? "border-red-500" : "border-gray-300"
              } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
            />
            <input
              id="aiDepartment"
              type="text"
              placeholder="Department"
              value={aiDepartment}
              onChange={(e) => {
                setAiDepartment(e.target.value);
                setErrors({ ...errors, aiDepartment: false });
              }}
              className={`border ${
                errors.aiDepartment ? "border-red-500" : "border-gray-300"
              } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
            />
          </div>
        </div>

        <div className="mb-6">
          <label
            className="text-gray-600 font-semibold block mb-2"
            htmlFor="date"
          >
            Date/Time{" "}
            {(errors.date || errors.time) && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <div className="flex flex-col sm:flex-row gap-3">
            <input
              id="date"
              type="date"
              value={date}
              onChange={(e) => {
                setDate(e.target.value);
                setErrors({ ...errors, date: false });
              }}
              className={`border ${
                errors.date ? "border-red-500" : "border-gray-300"
              } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
            />
            <input
              id="time"
              type="time"
              value={time}
              onChange={(e) => {
                setTime(e.target.value);
                setErrors({ ...errors, time: false });
              }}
              className={`border ${
                errors.time ? "border-red-500" : "border-gray-300"
              } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
            />
          </div>
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Store number/Address{" "}
            {errors.storeNumber && (
              <small className="text-red-500">* Required</small>
            )}
          </label>

          <input
            id="storeNumber"
            type="text"
            placeholder="Store number or address"
            value={storeNumber}
            onChange={(e) => {
              setStoreNumber(e.target.value);
              setErrors({ ...errors, storeNumber: false });
            }}
            className={`border ${
              errors.storeNumber ? "border-red-500" : "border-gray-300"
            } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
          />
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Incident type{" "}
            {(errors.incidentType || errors.other1) && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <div className="flex flex-wrap gap-4 mt-2">
            {["Recovery", "Trespass", "Loss", "Other"].map((option, index) => (
              <label className="flex items-center space-x-2" key={index}>
                <input
                  type="radio"
                  name="incidentType"
                  className="form-radio h-4 w-4"
                  value={option}
                  onChange={(e) => {
                    setIncidentType(e.target.value);
                    setErrors({ ...errors, incidentType: false });
                  }}
                />
                <span className="text-gray-700">{option}</span>
              </label>
            ))}
          </div>
          {incidentType == "Other" && (
            <input
              id="other1"
              type="text"
              placeholder="Type here..."
              value={other1}
              onChange={(e) => {
                setOther1(e.target.value);
                setErrors({ ...errors, other1: false });
              }}
              className={`mt-4 border ${
                errors.other1 ? "border-red-500" : "border-gray-300"
              } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
            />
          )}
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Suspect Name{" "}
            <span className="text-sm">(if unknown, type unknown)</span>{" "}
            {errors.suspectName && (
              <small className="text-red-500">* Required</small>
            )}
          </label>

          <input
            id="suspectName"
            type="text"
            placeholder="Write the suspect name"
            value={suspectName}
            onChange={(e) => {
              setSuspectName(e.target.value);
              setErrors({ ...errors, suspectName: false });
            }}
            className={`border ${
              errors.suspectName ? "border-red-500" : "border-gray-300"
            } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
          />
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Age{" "}
            {errors.age && <small className="text-red-500">* Required</small>}
          </label>
          <div className="flex flex-wrap gap-4 mt-2">
            {["Adult", "Juvenile"].map((option, index) => (
              <label className="flex items-center space-x-2" key={index}>
                <input
                  type="radio"
                  name="age"
                  className="form-radio h-4 w-4"
                  value={option}
                  onChange={(e) => {
                    setAge(e.target.value);
                    setErrors({ ...errors, age: false });
                  }}
                />
                <span className="text-gray-700">{option}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Gender{" "}
            {errors.gender && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <div className="flex flex-wrap gap-4 mt-2">
            {["Male", "Female"].map((option, index) => (
              <label className="flex items-center space-x-2" key={index}>
                <input
                  type="radio"
                  name="gender"
                  className="form-radio h-4 w-4"
                  value={option}
                  onChange={(e) => {
                    setGender(e.target.value);
                    setErrors({ ...errors, gender: false });
                  }}
                />
                <span className="text-gray-700">{option}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Race{" "}
            {(errors.race || errors.other) && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <div className="flex flex-wrap gap-4 mt-2">
            {[
              "White",
              "Black",
              "Hispanic",
              "Asian",
              "Pacific Islander",
              "Unknown",
              "Other",
            ].map((option, index) => (
              <label className="flex items-center space-x-2" key={index}>
                <input
                  type="radio"
                  name="race"
                  className="form-radio h-4 w-4"
                  value={option}
                  onChange={(e) => {
                    setRace(e.target.value);
                    setErrors({ ...errors, race: false });
                  }}
                />
                <span className="text-gray-700">{option}</span>
              </label>
            ))}
          </div>
          {race == "Other" && (
            <input
              id="other"
              type="text"
              placeholder="Type here..."
              value={other}
              onChange={(e) => {
                setOther(e.target.value);
                setErrors({ ...errors, other: false });
              }}
              className={`mt-4 border ${
                errors.other ? "border-red-500" : "border-gray-300"
              } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
            />
          )}
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Height/Weight/Build{" "}
            {errors.heightWeightBuild && (
              <small className="text-red-500">* Required</small>
            )}
          </label>

          <input
            id="heightWeightBuild"
            type="text"
            placeholder="Height, weight, and build"
            value={heightWeightBuild}
            onChange={(e) => {
              setHeightWeightBuild(e.target.value);
              setErrors({ ...errors, heightWeightBuild: false });
            }}
            className={`border ${
              errors.heightWeightBuild ? "border-red-500" : "border-gray-300"
            } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
          />
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Hair/Eye Color{" "}
            {errors.hairColor && (
              <small className="text-red-500">* Required</small>
            )}
          </label>

          <input
            id="hairColor"
            type="text"
            placeholder="Hair or eye color"
            value={hairColor}
            onChange={(e) => {
              setHairColor(e.target.value);
              setErrors({ ...errors, hairColor: false });
            }}
            className={`border ${
              errors.hairColor ? "border-red-500" : "border-gray-300"
            } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
          />
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Clothing{" "}
            {errors.clothing && (
              <small className="text-red-500">* Required</small>
            )}
          </label>

          <input
            id="clothing"
            type="text"
            placeholder="Clothing"
            value={clothing}
            onChange={(e) => {
              setClothing(e.target.value);
              setErrors({ ...errors, clothing: false });
            }}
            className={`border ${
              errors.clothing ? "border-red-500" : "border-gray-300"
            } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
          />
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Suspect kept under constant surveillance?{" "}
            {errors.surveillance && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <div className="flex flex-wrap gap-4 mt-2">
            {["Yes", "No"].map((option, index) => (
              <label className="flex items-center space-x-2" key={index}>
                <input
                  type="radio"
                  name="surveillance"
                  className="form-radio h-4 w-4"
                  value={option}
                  onChange={(e) => {
                    setSurveillance(e.target.value);
                    setErrors({ ...errors, surveillance: false });
                  }}
                />
                <span className="text-gray-700">{option}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Repeat offender?{" "}
            {errors.repeatOffender && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <div className="flex flex-wrap gap-4 mt-2">
            {["Yes", "No"].map((option, index) => (
              <label className="flex items-center space-x-2" key={index}>
                <input
                  type="radio"
                  name="repeatOffender"
                  className="form-radio h-4 w-4"
                  value={option}
                  onChange={(e) => {
                    setRepeatOffender(e.target.value);
                    setErrors({ ...errors, repeatOffender: false });
                  }}
                />
                <span className="text-gray-700">{option}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Subject trespassed?{" "}
            {errors.subjectTrespassed && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <div className="flex flex-wrap gap-4 mt-2">
            {["Yes", "No"].map((option, index) => (
              <label className="flex items-center space-x-2" key={index}>
                <input
                  type="radio"
                  name="subjectTrespassed "
                  className="form-radio h-4 w-4"
                  value={option}
                  onChange={(e) => {
                    setSubjectTrespassed(e.target.value);
                    setErrors({ ...errors, subjectTrespassed: false });
                  }}
                />
                <span className="text-gray-700">{option}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Violent offender?{" "}
            {errors.violentOffender && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <div className="flex flex-wrap gap-4 mt-2">
            {["Yes", "No"].map((option, index) => (
              <label className="flex items-center space-x-2" key={index}>
                <input
                  type="radio"
                  name="violentOffender"
                  className="form-radio h-4 w-4"
                  value={option}
                  onChange={(e) => {
                    setViolentOffender(e.target.value);
                    setErrors({ ...errors, violentOffender: false });
                  }}
                />
                <span className="text-gray-700">{option}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Describe what was stolen or recovered:{" "}
            {errors.stolenItems && (
              <small className="text-red-500">* Required</small>
            )}
          </label>

          <input
            id="lostAmount"
            type="text"
            placeholder="Describe in detail about items stolen"
            value={stolenItems}
            onChange={(e) => {
              setStolenItems(e.target.value);
              setErrors({ ...errors, stolenItems: false });
            }}
            className={`border ${
              errors.stolenItems ? "border-red-500" : "border-gray-300"
            } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
          />
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Total of value of merchandise stolen/recovered:{" "}
            {errors.lostAmount && (
              <small className="text-red-500">* Required</small>
            )}
          </label>

          <input
            id="lostAmount"
            type="text"
            placeholder="Value"
            value={lostAmount}
            onChange={(e) => {
              setLostAmount(e.target.value);
              setErrors({ ...errors, lostAmount: false });
            }}
            className={`border ${
              errors.lostAmount ? "border-red-500" : "border-gray-300"
            } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
          />
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Police responded{" "}
            {errors.policeResponded && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <div className="flex flex-wrap gap-4 mt-2">
            {["Yes", "No"].map((option, index) => (
              <label className="flex items-center space-x-2" key={index}>
                <input
                  type="radio"
                  name="policeResponded"
                  className="form-radio h-4 w-4"
                  value={option}
                  onChange={(e) => {
                    setPoliceResponded(e.target.value);
                    setErrors({ ...errors, policeResponded: false });
                  }}
                />
                <span className="text-gray-700">{option}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Police Agency{" "}
            {(errors.departmentName ||
              errors.officerName ||
              errors.caseNumber) && (
              <small className="text-red-500">* Required</small>
            )}
          </label>
          <div className="flex flex-col sm:flex-row gap-3">
            <input
              id="departmentName"
              type="text"
              placeholder="Department Name"
              value={departmentName}
              onChange={(e) => {
                setDepartmentName(e.target.value);
                setErrors({ ...errors, departmentName: false });
              }}
              className={`border ${
                errors.departmentName ? "border-red-500" : "border-gray-300"
              } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
            />
            <input
              id="officerName"
              type="text"
              placeholder="Officer name"
              value={officerName}
              onChange={(e) => {
                setOfficerName(e.target.value);
                setErrors({ ...errors, officerName: false });
              }}
              className={`border ${
                errors.officerName ? "border-red-500" : "border-gray-300"
              } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
            />
            <input
              id="caseNumber"
              type="text"
              placeholder="Case number"
              value={caseNumber}
              onChange={(e) => {
                setCaseNumber(e.target.value);
                setErrors({ ...errors, caseNumber: false });
              }}
              className={`border ${
                errors.caseNumber ? "border-red-500" : "border-gray-300"
              } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
            />
          </div>
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Summary of Incident-please attach a VOIDED copy of the receipt for
            any recovered or stolen merchandise for this incident.{" "}
            {errors.incidentSummary && (
              <small className="text-red-500">* Required</small>
            )}
          </label>

          <textarea
            id="incidentSummary"
            type="text"
            placeholder="Summarize the incident here. You can type it or dictate it"
            value={incidentSummary}
            rows={5}
            onChange={(e) => {
              setIncidentSummary(e.target.value);
              setErrors({ ...errors, incidentSummary: false });
            }}
            className={`border ${
              errors.incidentSummary ? "border-red-500" : "border-gray-300"
            } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
          ></textarea>
        </div>

        <div className="mb-6">
          <label className="text-gray-600 font-semibold block mb-2">
            Upload image related to the incident happened (Optional)
          </label>
          <div className="rounded-md border border-gray-600 bg-gray-50 w-full">
            <div className="m-4">
              <span className="flex justify-center items-center text-[12px] mb-1 text-red-500">
                {message}
              </span>
              <div className="flex items-center justify-center w-full">
                <label className="flex cursor-pointer flex-col w-full h-32 border-2 rounded-md border-dashed hover:bg-gray-100 hover:border-gray-300">
                  <div className="flex flex-col items-center justify-center pt-7">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      Select a photo
                    </p>
                  </div>
                  <input
                    ref={fileInputRef}
                    type="file"
                    onChange={handleFile}
                    className="opacity-0"
                    name="file"
                  />
                </label>
              </div>
              <div className="flex flex-wrap gap-2 mt-2">
                {files.length > 0 && (
                  <div className="relative overflow-hidden group">
                    <img
                      className="h-20 w-20 rounded-md object-cover"
                      src={URL.createObjectURL(files[0])}
                      alt="Preview"
                    />
                    <i
                      className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 fas fa-trash cursor-pointer text-white"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                      onClick={removeImage}
                    ></i>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {files.length > 0 && (
          <div className="mb-6">
            <label className="block font-semibold text-gray-700 mb-2">
              Description{" "}
              {errors.imageDescription ? (
                <small className="text-red-500">* Required</small>
              ) : (
                ""
              )}
            </label>
            <textarea
              onChange={(e) => {
                setImageDescription(e.target.value);
                setErrors({ ...errors, imageDescription: false });
              }}
              value={imageDescription}
              id="imageDescription"
              rows={3}
              className={`border ${
                errors.imageDescription ? "border-red-500" : "border-gray-300"
              } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
              placeholder="Please describe what the image is and why you are including it"
            ></textarea>
          </div>
        )}

        <div className="flex justify-center sm:justify-end mt-6">
          <button
            type="submit"
            className="bg-blue hover:bg-blue text-white py-2 px-4 rounded"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <>
                Generating <i className="fa-solid fa-spinner fa-spin"></i>
              </>
            ) : (
              "Get Incident Report"
            )}
          </button>
        </div>
      </div>
      {reportCounter && (
        <div>
          <div className="my-8">
            <label className="block font-semibold text-gray-700 text-start mb-2">
              Incident Report{" "}
              {errors.report && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <textarea
              value={report}
              onChange={(e) => {
                setReport(e.target.value);
                setErrors({ ...errors, report: false });
              }}
              rows="10"
              cols="50"
              placeholder="Your AI powered report will appear here..."
              className={`border ${
                errors.report ? "border-red-500" : "border-gray-300"
              } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
            />
          </div>

          <div class="relative inline-flex items-center justify-center w-full">
            <hr class="w-full h-0.5 my-8 bg-gray-200 border-0 rounded dark:bg-gray-700" />
            <div class="absolute px-4 bg-gray-50 -translate-x-1/2 left-1/2 text-gray-700">
              or
            </div>
          </div>

          <h2 className="font-bold text-xl">Submit your Report</h2>

          <div className="container mx-auto py-8">
            <h1 className="block font-semibold">
              How did we do?{" "}
              {!rating ? (
                <small className="text-xs font-semibold text-red-500">
                  * Required
                </small>
              ) : (
                ""
              )}
            </h1>
            <p className="mt-2 text-lightGrey leading-5 text-sm">
              Please let us know how we did with your support request. All
              feedback is appreciated to help us improve our offering!
            </p>
            <div className="flex flex-row items-center space-x-2 mt-4">
              {[1, 2, 3, 4, 5].map((number) => (
                <RatingButton
                  key={number}
                  isSelected={rating >= number}
                  onClick={() => setRating(number)}
                />
              ))}
            </div>
          </div>

          <div>
            <label className="font-semibold block mb-2">
            Share your report(Click Return after entering an email address)
              {!email && <small className="text-red-500">* Required</small>}
              {!isValidEmail && (
                <small className="text-red-500">
                  * Please type correct email address
                </small>
              )}
            </label>

            <input
              id="email"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              className={`border ${
                !email ? "border-red-500" : "border-gray-300"
              } rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
            />
          </div>

          <div className="mt-8 flex justify-between">
            <button
              className="border mt-8 rounded-md px-4 py-2.5 bg-transparent text-gray-700 text-sm hover:bg-gray-100"
              onClick={resetForm}
              disabled={loading1}
            >
              Reset
            </button>
            <button
              className="mt-8 text-white rounded-md px-4 py-2.5 text-sm bg-blue hover:bg-blue"
              onClick={sendReport}
              disabled={loading1}
            >
              {loading1 ? (
                <>
                  Sending <i className="fa-solid fa-spinner fa-spin"></i>
                </>
              ) : (
                "Send Report"
              )}
            </button>
          </div>
        </div>
      )}

      {isModalOpen && (
        <ModalComponent closeModal={() => setIsModalOpen(false)} />
      )}
    </div>
  );
};
