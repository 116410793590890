import { useState, useEffect } from "react";

function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    // Update the year every time the component mounts
    setCurrentYear(new Date().getFullYear());
  }, []); // Empty dependency array ensures it runs only once on mount

  return (
    <div className="bg-yellow text-white px-4 py-8 md:p-12 mt-8">
      {/* <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 border-t border-gray-700 mt-8 pt-8">
        Made by AP Ally team
      </div> */}
      <div className="container mx-auto flex flex-col md:flex-row items-center mt-8 pt-4 justify-center">
        <p>&copy; AP Ally 2024</p>
      </div>
    </div>
  );
}
export default Footer;
