import React, { useContext, useState, useRef } from "react";
import { FormContext } from "./FormContext";

const Step5 = () => {
  const {
    setStep5Input,
    step5DesInput,
    setStep5DesInput,
    error5,
    files,
    setFiles,
    setStep4,
    setStep5,
    loading,
    handleStep5Validation,
  } = useContext(FormContext);

  const [message, setMessage] = useState("");
  const fileInputRef = useRef(null);

  const handleFile = (e) => {
    if (files.length >= 1) {
      setMessage("Cannot upload more than 1 image");
      return;
    }

    setMessage("");
    let newFile = e.target.files[0];

    // Check file size (size is in bytes, so 15 MB is 15 * 1024 * 1024 bytes)
    const maxSize = 15 * 1024 * 1024; // 15 MB
    if (newFile.size > maxSize) {
      setMessage("File size exceeds 15 MB. Please upload a smaller file.");
      return;
    }

    const fileType = newFile["type"];
    const validImageTypes = [
      "image/png",
      "image/jpeg",
      "image/webp",
      "image/gif",
    ];
    if (!validImageTypes.includes(fileType)) {
      setMessage(
        "Invalid file type. Only PNG, JPG, JPEG, WEBP, and GIF are allowed."
      );
      return;
    }

    setFiles([newFile]);
    setStep5Input([newFile]);
    fileInputRef.current.value = null; // Clear the file input after upload
  };

  const removeImage = () => {
    setFiles([]);
    setStep5Input("");
    fileInputRef.current.value = null; // Clear the file input after deletion
  };

  return (
    <div>
      <div className="py-4">
        <label className="block font-semibold text-gray-700 mb-2">
          Optional-upload any related images here
          {error5 ? <small className="text-red-500">* Required</small> : ""}
        </label>
        <div className="rounded-md border border-gray-600 bg-gray-50 w-full">
          <div className="m-4">
            <span className="flex justify-center items-center text-[12px] mb-1 text-red-500">
              {message}
            </span>
            <div className="flex items-center justify-center w-full">
              <label className="flex cursor-pointer flex-col w-full h-32 border-2 rounded-md border-dashed hover:bg-gray-100 hover:border-gray-300">
                <div className="flex flex-col items-center justify-center pt-7">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                    Select a photo
                  </p>
                </div>
                <input
                  ref={fileInputRef}
                  type="file"
                  onChange={handleFile}
                  className="opacity-0"
                  name="file"
                />
              </label>
            </div>
            <div className="flex flex-wrap gap-2 mt-2">
              {files.length > 0 && (
                <div className="relative overflow-hidden group">
                  <img
                    className="h-20 w-20 rounded-md object-cover"
                    src={URL.createObjectURL(files[0])}
                    alt="Preview"
                  />
                  <i
                    className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 fas fa-trash cursor-pointer text-white"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                    onClick={removeImage}
                  ></i>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* {files.length > 0 && (
        <div className="py-4">
          <label className="block font-semibold text-gray-700 mb-2">
            Description
            {error5 ? <small className="text-red-500">* Required</small> : ""}
          </label>
          <textarea
            onChange={(e) => setStep5DesInput(e.target.value)}
            value={step5DesInput}
            id="step5DesInput"
            rows={3}
            className={`rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
            placeholder="Please describe what the image is and why you are including it"
          ></textarea>
        </div>
      )} */}

      <div>
        <button
          onClick={() => {
            setStep5(false);
            setStep4(true);
          }}
          className="mt-8 border px-4 py-2.5 rounded-md bg-transparent text-gray-700 text-sm hover:bg-gray-100"
          disabled={loading}
        >
          Go Back
        </button>
        <button
          onClick={handleStep5Validation}
          className="mt-8 float-right text-white text-sm rounded-md px-4 py-2.5 bg-blue hover:bg-blue"
          disabled={loading}
        >
          {loading ? (
            <>
              Generating <i className="fa-solid fa-spinner fa-spin"></i>
            </>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
};

export default Step5;
