import { useState, useEffect } from "react";
import Form from "./Form";
import { FormContext } from "./FormContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ChatStyle() {
  const [prompt, setPrompt] = useState("");
  const [step2Input, setStep2Input] = useState("");
  const [step3Input, setStep3Input] = useState("");
  const [step4Input, setStep4Input] = useState("");
  const [step5Input, setStep5Input] = useState("");
  const [step5DesInput, setStep5DesInput] = useState("");
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);
  const [error4, setError4] = useState(false);
  const [error5, setError5] = useState(false);
  const [step1, setStep1] = useState();
  const [step2, setStep2] = useState();
  const [step3, setStep3] = useState();
  const [step4, setStep4] = useState();
  const [step5, setStep5] = useState();
  const [step6, setStep6] = useState();
  const [selectedIncident, setSelectedIncident] = useState([]);

  const [email, setEmail] = useState();
  const [loading, setLoading] = useState();
  const [report, setReport] = useState();
  const [optionalFeedback, setOptionalFeedback] = useState();

  // images
  const [files, setFiles] = useState([]);

  const [loadingEmail, setLoadingEmail] = useState();

  const [rating, setRating] = useState(0);
  const [ratingError, setRatingError] = useState();

  const addIncident = (incident) => {
    setSelectedIncident(incident);
    setStep2(true);
  };

  const allStepsFalse = () => {
    setStep2(false);
    setStep3(false);
    setStep4(false);
    setStep5(false);
    setStep6(false);
  };

  const handleStep1Validation = (e) => {
    e.preventDefault();

    if (selectedIncident === "" || selectedIncident.length == 0) {
      setError1(true);
    } else {
      setStep2(true);
    }
  };

  const handleStep2Validation = (e) => {
    e.preventDefault();
    if (step2Input === "") {
      setError2(true);
    } else {
      setStep2(false);
      setStep3(true);
    }
  };

  const handleStep3Validation = (e) => {
    e.preventDefault();
    if (step3Input === "") {
      setError3(true);
    } else {
      setStep3(false);
      setStep4(true);
    }
  };

  const handleStep4Validation = async (e) => {
    e.preventDefault();
    if (step4Input === "") {
      setError4(true);
    } else {
      setStep4(false);
      setStep5(true);
    }
  };

  const uploadAndAnalyzeImage = async () => {
    // if (!step5Input.length && step5DesInput) {
    //   setError5(true);
    //   return;
    // }

    // if (step5Input.length > 0 && !step5DesInput) {
    //   setError5(true);
    //   return;
    // }

    // const formData = new FormData();
    // if(step5Input.length){
    //   formData.append("file", step5Input[0]);
    // }
return step5Input[0]
    // formData.append("userInput", step5DesInput);

    // try {
    //   const response = await fetch(
    //     `${process.env.REACT_APP_BACKEND_URL}/api/user/analyzeImage`,
    //     {
    //       method: "POST",
    //       body: formData,
    //     }
    //   );

    //   if (!response.ok) {
    //     throw new Error("Failed to upload and analyze image");
    //   }

    //   const result = await response.json();
    //   return result.data;
    // } catch (error) {
    //   console.error(error);
    //   throw error;
    // }
  };

  const handleStep5Validation = async (e) => {
    // if (
    //   (step5Input.length === 0 && step5DesInput) ||
    //   (step5Input.length > 0 && !step5DesInput)
    // ) {
    //   setError5(true);
    //   return;
    // }

    if (!step2Input || !step3Input || !step4Input) {
      return;
    }
    e.preventDefault();
    setLoading(true);

    try {
      let newPrompt = "";


 
        newPrompt = `Please generate a incident report in simple English based on the following information:
        
Incident Type: ${selectedIncident}
                              
Responder and Incident Overview: ${step2Input}
                              
Suspect Details: ${step3Input}
                              
Incident Specifics: ${step4Input}`;
      

      // Set the prompt and wait for the state to update
      setPrompt(newPrompt);

      // Use the updated prompt directly in the fetch call
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/chat`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userMessage: newPrompt,
            type: "chatStyle",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const finalResponse = await response.json();

      if (finalResponse) {
        setLoading(false);
        setReport(finalResponse.data);
        setStep5(false);
        setStep6(true);
      }
    } catch (e) {
      setLoading(false);
      toast.error("Error: Failed to generate report: " + e.message);
    }
  };

  const handleReSubmit = async (e) => {
    e.preventDefault();
    if (report === "" || optionalFeedback === "") {
      setError5(true);
      return;
    }
    setLoading(true);
    const reprompt = `Incident Report: ${report}
Changes to be made inside the report: ${optionalFeedback}
`;
    console.log(reprompt);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/chat`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userMessage: reprompt,
            type: "chatStyle",
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const finalResponse = await response.json();

      if (finalResponse) {
        setLoading(false);
        setReport(finalResponse.data);
        toast.success("Report generated successfully");

        // clear input fields
        setOptionalFeedback();
      }
    } catch (e) {
      setLoading(false);
      toast.error("Error: Failed to generate report");
      return e;
    }
  };

  const sendReport = async () => {
    let hasError = false;

    if (!report) {
      hasError = true;
    }

    if (rating === 0) {
      setRatingError(true);
      hasError = true;
    }

    if (!email) {
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const formData = new FormData();

    formData.append("userMessage", prompt);
    formData.append("type", "chatStyle");
    formData.append("rating", rating);

    if (files.length > 0) {
      files.forEach((file) => formData.append("file", file));
    }

    // Append report if available
    if (report) {
      formData.append("report", report);
    }

    formData.append("email", email);
    setLoadingEmail(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/email`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log(result);
      if (result?.success) {
        setLoadingEmail(false);

        toast.success("Report sent to email successfully");

        // clear input fields
        setStep2Input("");
        setStep3Input("");
        setStep4Input("");
        setSelectedIncident("");
        setFiles("");
      }
    } catch (e) {
      setLoadingEmail(false);
      console.error("Fetch error:", e.message);
      toast.error("Error: Failed to send report");
      return e;
    }
  };

  const contextValues = {
    step2Input,
    setStep2Input,
    step3Input,
    setStep3Input,
    step4Input,
    setStep4Input,
    step5Input,
    setStep5Input,
    step5DesInput,
    setStep5DesInput,
    handleStep1Validation,
    handleStep2Validation,
    handleStep3Validation,
    handleStep4Validation,
    handleStep5Validation,
    error1,
    error2,
    error3,
    error4,
    error5,
    step1,
    setStep1,
    step2,
    setStep2,
    step3,
    setStep3,
    step4,
    setStep4,
    step5,
    setStep5,
    step6,
    setStep6,
    addIncident,
    selectedIncident,
    email,
    setEmail,
    loading,
    setLoading,
    loadingEmail,
    setLoadingEmail,
    report,
    setReport,
    optionalFeedback,
    setOptionalFeedback,
    sendReport,
    handleReSubmit,
    allStepsFalse,
    files,
    setFiles,
    rating,
    setRating,
    ratingError,
  };
  return (
    <FormContext.Provider value={contextValues}>
      <div className="w-full mb-8">
        <ToastContainer />
        <Form />
      </div>
    </FormContext.Provider>
  );
}

export default ChatStyle;
