import { useContext } from "react";
import { FormContext } from "../ChatStyle/FormContext";

function RatingButton({ isSelected, onClick }) {
  const commonRatingButtonClasses =
    "w-8 h-8 flex justify-center items-center rounded-full button-sizing hover:text-yellow-400 active:text-yellow-400 cursor-pointer";
  const ratingButtonUnselectedClasses = `${commonRatingButtonClasses} text-gray-400`;
  const ratingButtonSelectedClasses = `${commonRatingButtonClasses} text-yellow-400`;

  return (
    <span
      className={
        isSelected ? ratingButtonSelectedClasses : ratingButtonUnselectedClasses
      }
      onClick={onClick}
    >
      <i
        class={`text-xl ${
          isSelected ? "fa-solid fa-star" : "fa-regular fa-star"
        }`}
      ></i>
    </span>
  );
}

function Rating() {
  const { rating, setRating, ratingError } = useContext(FormContext);

  return (
    <div className="container mx-auto py-8">
      <h1 className="block font-semibold">
        How did we do?{" "}
        {ratingError && rating == 0 ? (
          <small className="text-xs font-semibold text-red-500">
            * Required
          </small>
        ) : (
          ""
        )}
      </h1>
      <p className="mt-2 text-lightGrey leading-5 text-sm">
        Please let us know how we did with your support request. All feedback is
        appreciated to help us improve our offering!
      </p>
      <div className="flex flex-row items-center space-x-2 mt-4">
        {[1, 2, 3, 4, 5].map((number) => (
          <RatingButton
            key={number}
            isSelected={rating >= number}
            onClick={() => setRating(number)}
          />
        ))}
      </div>
    </div>
  );
}

export default Rating;
