import "./App.css";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Home from "./Components/Home/Home";
import Record from "./Components/Record/Record";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";

function App() {

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
