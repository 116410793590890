import React, { useContext, useState } from "react";

import { FormContext } from "./FormContext";

const Step3 = () => {
  const {
    step3Input,
    setStep3Input,
    setStep3,
    setStep2,
    handleStep3Validation,
    error3,
  } = useContext(FormContext);

  return (
    <div className="my-auto">
      <div className="py-8">
        <div>
          <div className="flex flex-col gap-2 mb-2">
            <label className="block font-semibold text-gray-700">
              Provide suspect details{" "}
              {error3 && step3Input === "" ? (
                <small className="text-red-500">* Required</small>
              ) : (
                ""
              )}
            </label>
            <label className="text-gray-700 text-sm italic">
              Good work, now please provide the following:
              <ul className="list-disc pl-5">
                <li>
                  Describe the suspect (including any known names, age category,
                  gender, race, physical characteristics like height, weight,
                  build, hair color, eye color, tattoos, and clothing). Be as
                  detailed as possible.
                </li>
              </ul>
            </label>
          </div>

          <textarea
            onChange={(e) => setStep3Input(e.target.value)}
            value={step3Input}
            id="step3Input"
            rows={8}
            className={`rounded w-full p-2 text-gray-700 focus:outline-none focus:shadow-outline flex-1`}
            placeholder={``}
          ></textarea>
          <i className="text-xs text-gray-600">
            Input and Edit with Mic & Keyboard
          </i>
        </div>
        <div>
          <button
            onClick={() => {
              setStep3(false);
              setStep2(true);
            }}
            className="mt-8 border px-4 py-2.5 rounded-md bg-transparent text-gray-700 text-sm hover:bg-gray-100"
          >
            Go Back
          </button>
          <button
            onClick={handleStep3Validation}
            className="mt-8 float-right text-white text-sm rounded-md px-4 py-2.5 bg-blue hover:bg-blue"
          >
            Next Step
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step3;
