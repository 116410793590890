import React, { useContext, useState } from "react";
import { FormContext } from "./FormContext";
import classNames from "classnames";

const Step1 = () => {
  const { addIncident, selectedIncident, error1 } = useContext(FormContext);

  return (
    <div className="my-auto">
      <h2 className="text-xl font-bold">Choose Your Incident Type</h2>
      <div className="py-8">
        {error1 && selectedIncident.length == 0 ? (
          <span className="float-right text-red-500 text-sm">
            This field is required
          </span>
        ) : (
          ""
        )}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 my-8 relative w-full">
          {[
            "Recovery",
            "Grab and Run theft",
            "Disorderly Conduct",
            "Parking Lot Incidents",
          ].map((option) => (
            <div
              key={option} // Don't forget to add a `key` prop when mapping in React
              className={classNames(
                "flex flex-col gap-8 border px-4 py-4 w-full rounded-lg cursor-pointer transition-all duration-500 items-center justify-center min-h-20",
                { "border-slate-900": selectedIncident === option }
              )}
              onClick={() => addIncident(option)}
            >
              <h2 className="text-center text-base">{option}</h2>
            </div>
          ))}
        </div>

        {/* <button
          onClick={handleStep1Validation}
          className="mt-8 float-right text-white text-sm rounded-md px-4 py-2.5 bg-blue hover:bg-blue"
        >
          Next Step
        </button> */}
      </div>
    </div>
  );
};

export default Step1;
